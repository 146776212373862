const initialState = {
  innerdress: {},
  cart: [],
  packages: [],
  personalised: [],

  services: {},
  subservices: {},

  checkoutstatus: 0,
};

if (typeof window != "undefined") {
  if (localStorage.getItem("innerdress")) {
    initialState.innerdress = JSON.parse(localStorage.getItem("innerdress"));
  }

  if (localStorage.getItem("cart")) {
    initialState.cart = JSON.parse(localStorage.getItem("cart"));
  }
  if (localStorage.getItem("packages")) {
    initialState.packages = JSON.parse(localStorage.getItem("packages"));
  }
  if (localStorage.getItem("services")) {
    initialState.services = JSON.parse(localStorage.getItem("services"));
  }
  if (localStorage.getItem("checkoutstatus")) {
    initialState.checkoutstatus = JSON.parse(
      localStorage.getItem("checkoutstatus")
    );
  }
  if (localStorage.getItem("subservices")) {
    initialState.subservices = JSON.parse(localStorage.getItem("subservices"));
  }
  if (localStorage.getItem("personalised")) {
    initialState.packages = JSON.parse(localStorage.getItem("personalised"));
  }
}

const dressReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_DRESS":
      console.log(action);

      localStorage.setItem("innerdress", JSON.stringify(action.payload));

      return {
        ...state,
        innerdress: action.payload,
      };
    case "ADD_TO_CART":
      if (
        state.cart.findIndex(
          (item) =>
            item.productid == action.payload.productid &&
            item.packages == action.payload.packages
        ) >= 0
      ) {
        var itemIndex = state.cart.findIndex(
          (item) =>
            item.productid == action.payload.productid &&
            item.packages == action.payload.packages
        );
        if (state.cart[itemIndex].packages == action.payload.packages) {
          var cartquantity = state.cart[itemIndex].cartquantity + 1;
          var cartamount = state.cart[itemIndex].price * cartquantity;
          state.cart[itemIndex] = {
            ...action.payload,
            cartquantity: cartquantity,
            cartamount: cartamount,
          };
        } else {
          state.cart[itemIndex] = {
            ...action.payload,
            cartquantity: state.cart[itemIndex].cartquantity,
          };
        }
      } else {
        state.cart.push({ ...action.payload });
      }
      localStorage.setItem("cart", JSON.stringify(state.cart));
      return {
        ...state,
        cart: state.cart,
      };

    case "ADD_PACKAGES":
      if (
        state.packages.findIndex(
          (item) =>
            item.id == action.payload.id &&
            item.ustring == action.payload.ustring
        ) >= 0
      ) {
        var itemIndex = state.packages.findIndex(
          (item) =>
            item.id == action.payload.id &&
            item.ustring == action.payload.ustring
        );
        var cartquantity = state.packages[itemIndex].cartquantity + 1;
        var cartamount = state.packages[itemIndex].amount * cartquantity;
        state.packages[itemIndex] = {
          ...action.payload,
          cartquantity: cartquantity,
          cartamount: cartamount,
        };
      } else {
        state.packages.push({
          ...action.payload,
          cartamount: action.payload.amount,
          cartquantity: state.cart?.find(
            (item) =>
              item.productid == action.payload.productid &&
              item.packages == action.payload.ustring
          )?.cartquantity,
        });
      }
      localStorage.setItem("packages", JSON.stringify(state.packages));
      return {
        ...state,
        packages: state.packages,
      };

    case "MINUS_TO_CART":
      if (
        state.cart.findIndex(
          (item) =>
            item.productid == action.payload.productid &&
            item.packages == action.payload.packages
        ) >= 0
      ) {
        var itemIndex = state.cart.findIndex(
          (item) =>
            item.productid == action.payload.productid &&
            item.packages == action.payload.packages
        );
        if (state.cart[itemIndex].packages == action.payload.packages) {
          var cartquantity = state.cart[itemIndex].cartquantity - 1;
          if (cartquantity <= 0) {
            cartquantity = 0;
            state.cart.splice(itemIndex, 1);
            localStorage.setItem("cart", JSON.stringify(state.cart));

            return {
              ...state,
              cart: state.cart,
            };
          }
          var cartamount = state.cart[itemIndex].price * cartquantity;

          state.cart[itemIndex] = {
            ...action.payload,
            cartquantity: cartquantity,
            cartamount: cartamount,
          };
        } else {
          state.cart[itemIndex] = {
            ...action.payload,
            cartquantity: state.cart[itemIndex].cartquantity,
          };
        }
      } else {
        state.cart.push({ ...action.payload });
      }
      localStorage.setItem("cart", JSON.stringify(state.cart));
      return {
        ...state,
        cart: state.cart,
      };

    case "MINUS_PACKAGES":
      if (
        state.packages.findIndex(
          (item) =>
            item.id == action.payload.id &&
            item.ustring == action.payload.ustring
        ) >= 0
      ) {
        var itemIndex = state.packages.findIndex(
          (item) =>
            item.id == action.payload.id &&
            item.ustring == action.payload.ustring
        );
        var cartquantity = state.packages[itemIndex].cartquantity - 1;
        var cartamount = state.packages[itemIndex].amount * cartquantity;

        if (cartquantity <= 0) {
          cartquantity = 0;
          state.packages.splice(itemIndex, 1);
          localStorage.setItem("packages", JSON.stringify(state.packages));

          return {
            ...state,
            packages: state.packages,
          };
        }

        state.packages[itemIndex] = {
          ...action.payload,
          cartquantity: cartquantity,
          cartamount: cartamount,
        };
      } else {
        state.packages.push({
          ...action.payload,
          cartamount: action.payload.amount,
          cartquantity: state.cart?.find(
            (item) =>
              item.productid == action.payload.productid &&
              item.packages == action.payload.ustring
          )?.cartquantity,
        });
      }
      localStorage.setItem("packages", JSON.stringify(state.packages));
      return {
        ...state,
        packages: state.packages,
      };

    case "ADD_SERVICES":
      console.log(action);

      localStorage.setItem("services", JSON.stringify(action.payload));

      return {
        ...state,
        services: action.payload,
      };
    case "ADD_SUB_SERVICES":
      console.log(action);

      localStorage.setItem("subservices", JSON.stringify(action.payload));

      return {
        ...state,
        subservices: action.payload,
      };

    case "CHECKOUT_STATUS":
      localStorage.setItem("checkoutstatus", JSON.stringify(action.payload));
      return {
        ...state,
        checkoutstatus: action.payload,
      };

    case "MINUS_PACKAGES_WITH_CART":
      if (
        state.packages.findIndex(
          (item) =>
            item.productid == action.payload.productid &&
            item.ustring == action.payload.ustring
        ) >= 0
      ) {
        var itemIndex = state.packages.findIndex(
          (item) =>
            item.productid == action.payload.productid &&
            item.ustring == action.payload.ustring
        );

        if (
          state.cart?.find(
            (item) =>
              item.productid == action.payload.productid &&
              item.packages == action.payload.ustring
          )?.cartquantity
        ) {
          var cartquantity = state.packages[itemIndex].cartquantity - 1;
          var cartamount = state.packages[itemIndex].amount * cartquantity;
        } else {
          var cartquantity = 0;
          var cartamount = 0;
        }

        if (cartquantity <= 0) {
          cartquantity = 0;
          state.packages = state.packages.filter(
            (item) =>
              item.productid !== action.payload.productid &&
              item.ustring !== action.payload.ustring
          );
          localStorage.setItem("packages", JSON.stringify(state.packages));

          return {
            ...state,
            packages: state.packages,
          };
        }

        state.packages[itemIndex] = {
          ...state.packages[itemIndex],
          cartquantity: cartquantity,
          cartamount: cartamount,
        };
      }
      localStorage.setItem("packages", JSON.stringify(state.packages));
      return {
        ...state,
        packages: state.packages,
      };
    case "ADD_PERSONALISED":
      if (
        state.personalised.findIndex((item) => item.id == action.payload.id) >=
        0
      ) {
        var itemIndex = state.personalised.findIndex(
          (item) => item.id == action.payload.id
        );
        var cartquantity = state.personalised[itemIndex].cartquantity + 1;
        var cartamount = state.personalised[itemIndex].amount * cartquantity;
        state.personalised[itemIndex] = {
          ...action.payload,
          cartquantity: cartquantity,
          cartamount: cartamount,
        };
      } else {
        state.personalised.push({
          ...action.payload,
          cartamount: action.payload.amount,
          cartquantity: 1,
        });
      }
      localStorage.setItem("personalised", JSON.stringify(state.personalised));
      return {
        ...state,
        personalised: state.personalised,
      };

    case "MINUS_PERSONALISED":
      if (
        state.personalised.findIndex((item) => item.id == action.payload.id) >=
        0
      ) {
        var itemIndex = state.personalised.findIndex(
          (item) => item.id == action.payload.id
        );
        var cartquantity = state.personalised[itemIndex].cartquantity - 1;
        var cartamount = state.personalised[itemIndex].amount * cartquantity;

        if (cartquantity <= 0) {
          cartquantity = 0;
          state.personalised.splice(itemIndex, 1);
          localStorage.setItem("packages", JSON.stringify(state.personalised));

          return {
            ...state,
            personalised: state.personalised,
          };
        }

        state.personalised[itemIndex] = {
          ...action.payload,
          cartquantity: cartquantity,
          cartamount: cartamount,
        };
      } else {
        state.personalised.push({
          ...action.payload,
          cartamount: action.payload.amount,
          cartquantity: 0,
        });
      }
      localStorage.setItem("personalised", JSON.stringify(state.personalised));
      return {
        ...state,
        personalised: state.personalised,
      };

    case "CLEAR_PERSONALISED": {
      state.personalised.length = 0;
      localStorage.setItem("personalised", JSON.stringify(state.personalised));

      return {
        ...state,
        personalised: state.personalised,
      };
    }

    default:
      return state;
  }
};
export default dressReducer;
