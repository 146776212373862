import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import api from "../../Api/api";
import useAuth from "../../hooks/useAuth";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

function Header() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const cartreduxdata = useSelector((state) => state.cart);

  const { auth, setAuth } = useAuth();
  const mutationlogout = useMutation(
    (body) => {
      var authstring = auth.token ?? auth.user.refreshtoken;
      const headers = {
        headers: {
          authorization: "bearer" + " " + authstring,
        },
      };
      return api.post("admin/logout", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
      },
      onSuccess: (data, variables, context) => {
        console.log("here");
        localStorage.clear();
        setAuth({});
        window.location.reload();
        navigate("/");
      },
    }
  );

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setAuth({ token: localStorage.getItem("token") });
    }
  }, []);

  console.log(auth);

  const handleMutate = () => {
    mutationlogout.mutate({ token: auth.token ?? auth.user.refreshtoken });
  };

  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="nav-section">
        <div className="nav">
          <div className="container">
            <nav class="navbar navbar-expand-lg">
              <div class="container-fluid">
                <a class="navbar-brand nm" href="#">
                  <img
                    src="images/logo.png "
                    alt="image"
                    style={{ maxWidth: "135px" }}
                  />
                </a>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarText"
                  aria-controls="navbarText"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarText">
                  <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        aria-current="page"
                        href="#"
                        onClick={() => navigate("/")}
                      >
                        HOME
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="#"
                        onClick={() => navigate("/about")}
                      >
                        ABOUT US
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="#"
                        onClick={() => navigate("/services")}
                      >
                        SERVICES
                      </a>
                    </li>
                    {/* <li class="nav-item">
                      <a
                        class="nav-link"
                        href="#"
                        onClick={() => navigate("/products")}
                      >
                        Products
                      </a>
                    </li> */}

                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="#"
                        onClick={() => navigate("/contact")}
                      >
                        CONTACT US
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href="#"
                        onClick={() => navigate("/fitcheck")}
                      >
                        Gallery
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        class="nav-link"
                        href="#"
                        onClick={() => navigate("/faq")}
                      >
                        FAQ<span style={{ textTransform: "lowercase" }}>s</span>
                      </a>
                    </li>

                    {auth?.user || auth?.token ? (
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="#"
                          onClick={() => navigate("/myaccount")}
                        >
                          Myaccount
                        </a>
                      </li>
                    ) : (
                      <>
                        {/* <li class="nav-item">
                        <a
                          class="nav-link"
                          href="#"
                          onClick={() => navigate("/login")}
                        >
                          Login
                        </a>
                      </li> */}
                      </>
                    )}

                    {auth?.user || auth.token ? (
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          href="#"
                          onClick={() => handleMutate()}
                        >
                          LOGOUT
                        </a>
                      </li>
                    ) : (
                      <></>
                    )}
                    {/* <Link to="/cart">
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <i className="fa-solid fa-cart-shopping"></i>
                        </a>
                      </li>
                    </Link> */}
                  </ul>
                </div>

                <div className="sidebar">
                  <Button variant="" onClick={handleShow}>
                    <i
                      class="fa-sharp fa-solid fa-bars"
                      style={{
                        color: "#000",
                        fontSize: "16px",
                        background: "#fff",
                        borderRadius: "6px",
                        padding: "10px",
                      }}
                    ></i>
                  </Button>

                  <Offcanvas show={show} onHide={handleClose} backdrop="static">
                    <Offcanvas.Header closeButton>
                      {/* <Offcanvas.Title>
                        <img
                          src="images/menu-logo.png"
                          alt="image"
                          style={{ maxwidth: "20%" }}
                        ></img>
                      </Offcanvas.Title> */}
                    </Offcanvas.Header>
                    <Offcanvas.Body className="p-0">
                      <ul>
                        <li onClick={() => navigate("/")}>
                          <i class="fa-solid fa-house-user"></i>
                          <a href="#" onClick={handleClose} >HOME</a>
                        </li>
                        <li onClick={() => navigate("/about")}>
                          <i class="fa-solid fa-eject"></i>
                          <a href="#" onClick={handleClose}>
                            ABOUT US
                          </a>
                        </li>
                        <li onClick={() => navigate("/services")}>
                          <i class="fa-solid fa-handshake-angle"></i>
                          <a href="#" onClick={handleClose} >SERVICES</a>
                        </li>
                        <li onClick={() => navigate("/contact")}>
                          <i class="fa-solid fa-address-book"></i>
                          <a href="#" onClick={handleClose}  >CONTACT US</a>
                        </li>
                        
                        <li onClick={() => navigate("/fitcheck")}>
                          <i class="fa-solid fa-address-book"></i>
                          <a href="#" onClick={handleClose}  >Gallery</a>
                        </li>
                        <li onClick={() => navigate("/faq")}>
                          <i class="fa-solid fa-face-smile"></i>
                          <a href="#" onClick={handleClose} >
                            FAQ
                            <span style={{ textTransform: "lowercase" }}>
                              s
                            </span>
                          </a>
                        </li>
                        {auth?.user || auth?.token ? (
                          <li onClick={() => navigate("/myaccount")}>
                            <i class="fa-solid fa-user"></i>{" "}
                            <a href="#" onClick={handleClose} >My Account</a>
                          </li>
                        ) : (
                          <>
                            {/* <li>
                          <i class="fa-solid fa-user"></i> <a href="#">LOGIN</a>
                        </li> */}
                          </>
                        )}
                        {/* <li onClick={() => navigate("/cart")} >
                          <i class="fa-sharp fa-solid fa-cart-shopping"></i>
                          <a href="#">CART</a>
                        </li> */}

                        {auth?.user || auth.token ? (
                          <li>
                            <i class="fa-sharp fa-solid fa-cart-shopping"></i>
                            <a href="#" onClick={() => handleMutate()}>
                              Logout
                            </a>
                          </li>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </Offcanvas.Body>
                  </Offcanvas>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

export default Header;
