import React from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useMutation } from "@tanstack/react-query";
import api from "./Api/api";
import { useSelector } from "react-redux";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OrderSummary from "./OrderSummary";
const Details = () => {
  const cartreduxdata = useSelector((state) => state.cart);
  const packagesreduxdata = useSelector((state) => state.packages);
  const navigate = useNavigate();

  const [name, setName] = useState();
  const [lastname, setLastName] = useState();
  const [address, setAddress] = useState();
  const [mobile, setMobile] = useState();
  const [city, setCity] = useState();
  const [postalcode, setPostalcode] = useState();
  const [user, setUser] = useState({});


  useEffect(() => {
    if (localStorage.getItem("user")) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  }, []);

  var total = 0;
  cartreduxdata.forEach((item) => {
    total += item.cartamount;
  });
  var packtotal = 0;

  packagesreduxdata.forEach((item) => {
    if (item && item.cartamount) {
      packtotal += item.cartamount;
    }
  });

  var gtotal = parseInt(total) + parseInt(packtotal);

  const mutationSubmit = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "application/json",
        },
      };
      return api.post("users/addcustomers", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
      },
      onSuccess: (data, variables, context) => {},
    }
  );

  

  const handleSubmit = () => {
    var body = {
      name,
      lastname,
      city,
      mobile,
      address,
      postalcode,
      userid: user?.id,
    };
    console.log(body);
    mutationSubmit.mutate(body);
    navigate("/checkout");
  };

  

  return (
    <div className="checkoutPageSec">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h3 className="heading">Shipping Details</h3>

            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First name"
                  onChange={(e) => setName(e.target.value)}
                />
                <Form.Text className="text-muted"></Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Mobile"
                  onChange={(e) => setMobile(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Address"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="city"
                  onChange={(e) => setCity(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Post Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Post Code"
                  onChange={(e) => setPostalcode(e.target.value)}
                />
              </Form.Group>

              <Button
                variant="success"
                className="btn2"
                onClick={() => handleSubmit()}
              >
                Proceed to Checkout
              </Button>
            </Form>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <OrderSummary />
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  );
};

export default Details;
