import React from "react";

function Footer() {
  return (
    <React.Fragment>
      <footer className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-4 ">
              <div className="footer-left">
                <div className="footer-top">
                  <a href="#">
                    <img
                      src="images/logo.png "
                      alt="image"
                      style={{ maxWidth: "135px" }}
                    />
                  </a>
                </div>
                <div className="footer-buttom mt-3">
                We are a wedding dress cleaning specialist based in Beckenham with over twenty years of experience in the bridal industry and general dry cleaning.
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="footer-inner">
                <h4>OFFICE INFO</h4>
                <ul>
                  <li>
                    <a href="mailto:contact@weddingdresscleaninglondon.co.uk">
                    <i class="fa-solid fa-envelope"></i>contact@weddingdresscleaninglondon.co.uk
                    </a>
                  </li>
                  <li>
                    <a href="https://goo.gl/maps/PsDoJ3aLWRNG3No77" target="_blank">
                      <i className="fas fa-map-marker-alt"></i>92 Elmers End Rd Beckenham Kent BR34TA 
                    </a>
                  </li>
                  <li>
                    <a href="tel:+102036054411">
                      <i className="fas fa-phone"></i>02036054411
                    </a>
                  </li>
                </ul>
                <ul className="socialIconsListFtr">
                  <li><a target="_blank" href="https://www.facebook.com/Wedding-Dress-Cleaning-London-101582852742620"><i className="fa-brands fa-facebook-f"></i></a></li>
                  <li><a target="_blank" href="https://www.youtube.com/channel/UC9eONl8pKfwwgqQegoc5MAg"><i class="fa-brands fa-youtube"></i></a></li>
                </ul>
              </div>
            </div>

            <div className="col-md-2">
              <div className="footer-inner">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <a href="/terms">
                      <span>+</span> Terms of Use
                    </a>
                  </li>
                  <li>
                    <a href="/contact">
                      <span>+</span> Contact Us
                    </a>
                  </li>
                  <li>
                    <a href="/about">
                      <span>+</span> About Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
