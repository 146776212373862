import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api, { imageapi } from "./Api/api";
import { useQuery } from "@tanstack/react-query";

const SubServices = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const servicedata = useSelector((state) => state.services);

  useEffect(() => {
    if (!servicedata.hasSubservices) {
      navigate("/dresses");
    }
  }, []);

  const {
    data: subservicedata,

    refetch,
  } = useQuery(
    ["subservices"],
    () => api.get(`users/getsubservices/${servicedata?.id}`),
    {
      select: (res) => res.data,
    }
  );

  const handleClick = (item) => {
    dispatch({ type: "ADD_SUB_SERVICES", payload: item });
  };
  return (
    <div>
      <div className="innerbanner-section section">
        <h1 className="text-center mt-5">Wedding Dress</h1>
      </div>

      <div className="service-section section">
        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            {subservicedata?.data.map((item, index) => {
              return (
                <div className="mt-4 col-lg-4 col-sm-6" key={index}>
                  <div
                    className="service-inner subCatgServiceGrid"
                    align="center"
                  >
                    <div className="top">
                      <img
                        src={`${imageapi}images/${item.picture}`}
                        alt="image"
                      />
                    </div>
                    <div className="buttom">
                      <h3>
                        <Link to="/dresses">
                          <span onClick={() => handleClick(item)}>
                            <a href="#">
                            {item.name} <i class="fa-solid fa-arrow-right"></i>
                            </a>
                          </span>
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubServices;
