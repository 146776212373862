import React from "react";
import { Accordion } from "react-bootstrap";

const Faq = () => {
  return (
    <div>
      <div className="innerbanner-section section contactSec">
        <h1 className="text-center">Frequently Asked Questions</h1>
      </div>

      <div className="faq-section section">
        <div className="container">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                1. When Should I Get My Wedding Dress Cleaned?
              </Accordion.Header>
              <Accordion.Body>
                With wedding dress cleaning, time is of the essence. If you’re
                planning to have your dress cleaned and preserved as a way to
                remember your wedding day, we recommend that you arrange for it
                to be collected by us as soon after your wedding as you can. If
                possible, factor wedding dress cleaning into the plans for your
                wedding. Don’t worry if you’re going on honeymoon straight after
                your big day, we can collect your gown from anywhere. Maybe ask
                one of your bridesmaids, parents or other close friends to make
                sure it’s collected.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                2. Do I Really Need My Wedding Dress Cleaned?
              </Accordion.Header>
              <Accordion.Body>
                There are lots of reasons why you might choose to have your
                wedding dress cleaned. You might want to have your bridal gown
                cleaned before the ceremony, maybe because you are wearing a
                vintage wedding dress or because you are planning to renew your
                vows. Alternatively, you may want your dress cleaned after your
                wedding in order to sell it. The most common reason to have a
                wedding dress cleaned though is in order to keep it as a
                beautiful memory of your wedding day. In this case you’ll want
                to make sure that you use a specialist wedding dress cleaning
                and preservation company, such as Wedding Dress Cleaners.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                3. What If My Wedding Dress Doesn’t Look Dirty?
              </Accordion.Header>
              <Accordion.Body>
                There are a few reasons to get your dress cleaned even if it
                doesn’t look dirty. Some marks on wedding dresses are difficult
                to see without using specialist equipment, while other smaller
                visible stains may become fixed on the gown or become more
                noticeable if they aren’t treated quickly. Leaving stains on
                dresses then can lead to longer term damage.
                <br />
                Dry cleaning your wedding dress will also help to freshen it up
                – particularly if you choose to ozone clean your bridal -gown
                and help to keep it looking great for longer.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                4. Can I Clean My Wedding Dress Myself?
              </Accordion.Header>
              <Accordion.Body>
                It may be tempting to clean your wedding dress yourself,
                particularly if it appears to have no, or only superficial,
                marks. However, we really wouldn’t recommend it. Failing to use
                a professional wedding dress cleaning company can damage your
                bridal gown. Investing in your wedding dress by having it
                cleaned professionally will ensure that you and your loved ones
                can enjoy it for years to come.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                5. Do You Collect and Deliver Back in My Area?
              </Accordion.Header>
              <Accordion.Body>
                We collect and deliver back to all mainland postcodes in
                England, Wales and Scotland.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                6. Your Website Says You Use Miele WetCare. My Wedding Dress Is
                Dry Clean Only, Can You Clean It Safely?
              </Accordion.Header>
              <Accordion.Body>
                Yes. The Miele WetCare cleaning system is an innovative cleaning
                method that is suitable for almost all fabrics, including those
                marked ‘dry clean only’ and those that specify cleaning methods.
                The advantage of the Miele system is that it doesn’t use harsh
                chemicals, making it kinder to both the environment and your
                wedding dress. You also avoid the strong smell that you usually
                associate with traditional dry cleaning methods.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>
                7. What are the Benefits of UV Scanning a Wedding Dress?
              </Accordion.Header>
              <Accordion.Body>
                While you may be able see some stains on your wedding gown, not
                all are visible to the naked eye. Substances such as white wine
                may not be noticed but will stain your gown over time. In
                addition, even ‘invisible’ stains can damage the fabric of your
                gown.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                8. What is Ozone Cleaning and What Difference Will It Make to My
                Wedding Dress?
              </Accordion.Header>
              <Accordion.Body>
                Ozone cleaning is an environmentally friendly and chemical free
                method of cleaning your wedding dress. Ozone cleaning also
                disinfects the fabric of your gown, killing 99.99% of all
                viruses and bacteria, including Coronavirus.
                <br />
                As an added bonus, ozone cleaning breaks down smells such as
                sweat, perfume and smoke to leave you wedding dress smelling
                fresher than ever. If you’ve smelt the air after a thunderstorm,
                then you’ll have an idea of how fresh your gown will smell after
                ozone cleaning.
                <br />
                Finally, ozone cleaning helps with the preservation of your
                wedding gown as it can help with the prevention and removal of
                mould.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>
                9. Why do I Need A Storage Box?
              </Accordion.Header>
              <Accordion.Body>
                Our bespoke handmade preservation boxes are more than just
                storage boxes. They help protect your wedding dress from damage
                that can be caused by exposure to light or dust, which may cause
                the wedding dress to mark over time.
                <br />
                Our preservation boxes also allow your bridal gown to ‘breathe’,
                reducing the risk of mildew forming, while the pH neutral
                material of the box and the acid-free tissue paper your gown is
                packed in will both help prevent discolouration.
                <br />
                Last but not least, our preservation boxes are also beautiful
                pieces in themselves, designed to safeguard your precious
                wedding memories for years to come.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>
                10. Can You Clean My Shoes Too?
              </Accordion.Header>
              <Accordion.Body>
                Yes, we do offer a hand finished shoe cleaning and disinfectant
                service and we can also present your wedding shoes in a
                beautiful hand made preservation box in the same design as your
                wedding dress box. However, whilst we always endeavour to obtain
                the very best results, we sadly cannot guarantee to remove all
                stains or marks from your shoes and some marks may only fade in
                cleaning. Any scuffs or damage to the shoes from your wedding
                day will be treated but will remain after cleaning.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header>
                11. Can You Remove All The Stains From My Wedding Dress?
              </Accordion.Header>
              <Accordion.Body>
                We can never guarantee that every stain can be removed from a
                garment and any company that suggests it can guarantee ‘complete
                stain removal’ is, is not being transparent nor totally honest
                and in our opinion best avoided.
                <br />
                What we can guarantee is that we will take care to ensure every
                wedding dress we clean is treated in the most appropriate way
                taking into account its condition, material and age. We will
                always err on the side of caution and use our extensive
                experience to select the best cleaning process to use.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="12">
              <Accordion.Header>
                12. Can a Wedding Dress Get Damaged When It Is Cleaned?
              </Accordion.Header>
              <Accordion.Body>
                When we receive your wedding gown the first thing we do is to
                examine and photograph it. This allows us to identify areas of
                weakness or damage that may need repairing or treating with
                extra care during the cleaning process.
                <br />
                We also either secure or remove more fragile areas of
                decoration. Where decoration and adornments cannot reasonably be
                removed we test them to make sure that they are suitable for
                cleaning. On some occasions we use protective fabric to
                safeguard delicate areas. There may be an additional charge when
                we need to use protective tailoring techniques.
                <br />
                Very occasionally weaknesses in the fabric of your wedding gown,
                which were not apparent prior to cleaning, may result in some
                minor damage during the cleaning process. In a few cases beads
                and trim may become loose or damaged during cleaning. This can
                happen with even the most rigorous preparation due to the
                delicate nature of some bridal gowns. However our years of
                experience mean that this happens very rarely.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
              <Accordion.Header>
                13. Can You Repair My Wedding Dress?
              </Accordion.Header>
              <Accordion.Body>
                Yes. In most cases our skilled specialist wedding dress
                tailoring team are able to repair your gown. Once we have
                received your dress, we will assess what needs to be done and
                will give you a quote for the repair.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="14">
              <Accordion.Header>
                14. My Wedding Dress Needs Specialist Cleaning. Can You Do That?
              </Accordion.Header>
              <Accordion.Body>
                The label in your wedding dress may say ‘do not dry clean’,
                ‘spot clean only’ or specify a particular cleaning method such
                as the Baptiste Process®. As we use a range of measures and
                treat each dress individually, we can safely clean all wedding
                dresses, whatever the label says. We base the method we use to
                clean your dress on many factors including condition, fabric and
                age.
                <br />
                Please note: the Baptiste Process® is simply the name given by
                one dry cleaner to the method they use. The methods we use are
                tried and tested on bridal gowns that carry this label.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="45">
              <Accordion.Header>
                15. Do You Insure My Wedding Dress When It Is Cleaned?
              </Accordion.Header>
              <Accordion.Body>
                Insurance for dresses up to a value of £5000 is included in all
                our wedding dress cleaning packages. If your dress cost more
                than this when you purchased it we will add extra insurance for
                a small premium. It is important that you do not underestimate
                the value of your wedding dress to make certain that it is
                insured up to the appropriate level.
                <br />
                We are sometimes asked what would happen in the extremely rare
                event of a problem arising and a claim being necessary. In such
                an event a valuation would be obtained by our insurers according
                to industry guidelines* which means that the dress and any
                additional items are valued at half the purchase price.
                (*Textile Services Association has valuation guidelines agreed
                with Office of Fair Trading and Trading Standards).
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="16">
              <Accordion.Header>
                16. I am Having My Wedding Dress Cleaned Before The Ceremony.
                Can I Wear It Straight From the Box?
              </Accordion.Header>
              <Accordion.Body>
                Once dresses are cleaned, they are pressed and carefully boxed.
                While we make every effort to minimise creases when we pack your
                dress, you may need to steam or repress your bridal gown before
                the ceremony. We therefore recommend that you have your dress
                returned to you at least a week before your wedding day, as at
                the very least it will require removing from the box and
                hanging.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="17">
              <Accordion.Header>
                17. How Do I Pack My Dress – Do I Need a box?
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>Please print out two address labels</li>
                  <li>
                    Place your dress and any other pieces you are sending to be
                    cleaned in a bag, preferably a plastic bag as it provides
                    additional protection from water ingress. If you send us
                    your dress carrier and hanger, then we will return these to
                    you. Please note that veils may be packaged at the bottom of
                    your preservation box upon return.
                  </li>
                  <li>
                    Place that bag in a cardboard box or other suitable strong
                    container. Some people use an old suitcase, storage box or
                    similar but please note that unless specifically agreed and
                    paid for, these will not be returned to you.
                  </li>
                  <li>
                    Put one address label in the box. This should help to
                    repatriate your dress should it be necessary. Secure the
                    other address label on the outside of the box so the address
                    is clearly visible.
                  </li>
                  <li>
                    Our courier will add their own labelling and address details
                    when they collect the dress from you.
                  </li>
                  <li>
                    Please note that we cannot accept dresses given to the
                    courier on hangers. They must be in a box for insurance
                    cover.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="18">
              <Accordion.Header>
                18. Can I Just Give You My Dress On A Hanger?
              </Accordion.Header>
              <Accordion.Body>
                No. You must pack your dress as described above. Our couriers
                will not accept items which are not boxed for insurance purposes
                and we cannot take any responsibility it these guidelines are
                not followed.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Faq;
