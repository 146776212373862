import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api, { imageapi } from "./Api/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";

const InnerDresses = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dressdata = useSelector((state) => state.innerdress);
  const cartreduxdata = useSelector((state) => state.cart);
  const packagesreduxdata = useSelector((state) => state.packages);
  const personalisedreduxdata = useSelector((state) => state.personalised);
  const servicedata = useSelector((state) => state.services);
  const subservicedata = useSelector((state) => state.subservices);

  const [price, setPrice] = useState(dressdata.price);
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [topen, setTOpen] = useState(false);
  const [tempString, setTempString] = useState();
  const [user, setUser] = useState();
  const [showPackages, setShowPackages] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [has, setHas] = useState(false);

  const {
    data: packagedata,

    refetch,
  } = useQuery(
    ["package"],
    () =>
      api.get(
        `users/getpackages/${
          dressdata?.gender ? dressdata?.gender : subservicedata?.name
        }`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      select: (res) => res.data,
    }
  );
  useEffect(() => {
    window.scrollTo(0, 100);
  }, []);

  var total = 0;
  cartreduxdata.forEach((item) => {
    total += item.cartamount;
  });
  var packtotal = 0;

  packagesreduxdata.forEach((item) => {
    if (item && item.cartamount) {
      packtotal += item.cartamount;
    }
  });
  var personalisedtotal = 0;
  personalisedreduxdata.forEach((item) => {
    if (item && item.cartamount) {
      personalisedtotal += item.cartamount;
    }
  });
  useEffect(() => {
    if (localStorage.getItem("user")) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
  }, []);
  console.log("user", user);

  useEffect(() => {
    if (
      dressdata?.status ? true : servicedata?.type && servicedata.hasSubservices
    ) {
      setShowPackages(true);
    }
  }, [servicedata]);
  console.log(showPackages);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("refresh"))) {
      window.location.reload();
      localStorage.setItem("refresh", JSON.stringify(0));
    }
  }, []);

  const mutationaddtocart = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "application/json",
        },
      };
      return api.post("users/addtocart", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
      },
      onSuccess: (data, variables, context) => {},
    }
  );

  const mutationpackage = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "application/json",
        },
      };
      return api.post("users/addtopackage", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
      },
      onSuccess: (data, variables, context) => {},
    }
  );

  const handleCal = (value, e) => {
    console.dir(e.target.value);
    console.log("see here", value.status);

    if (value.id == 1 && e.target.value == "55" && !has) {
      setPrice(dressdata.price + parseInt(e.target.value));
      setHas(true);
      var personalised = {};
      personalised["id"] = value.id;
      personalised["name"] = value.name;
      personalised["amount"] = 55;
      personalised["cartquantity"] = 1;
      personalised["productid"] = dressdata.id;

      dispatch({ type: "ADD_PERSONALISED", payload: personalised });

      return;
    } else {
      console.log("byr byr");
      setHas(false);
      dispatch({ type: "CLEAR_PERSONALISED" });
    }
    if (value.status) {
      packagedata.data.forEach((item) => {
        if (item.id == value.id) {
          item.status = 0;
        }
      });
    } else {
      packagedata.data.forEach((item) => {
        if (item.id == value.id) {
          item.status = 1;
        }
      });
    }
    var tempPackagedata = packagedata.data;
    var subPrice = 0;
    tempPackagedata.forEach((item) => {
      if (item.status) {
        subPrice += item.amount;
      }
    });
    var tempAmount = dressdata.price + parseInt(subPrice);
    setPrice(tempAmount);
    console.log(packagedata.data);
  };

  const handleCart = () => {
    var temppackagecart = [];
    packagedata.data &&
      packagedata.data.forEach((item, index) => {
        if (item.status) {
          var packageCart = {};
          packageCart["id"] = item.id;
          packageCart["name"] = item.name;
          packageCart["amount"] = item.amount;
          packageCart["cartquantity"] = 1;
          packageCart["productid"] = dressdata.id;
          // packageCart["userid"] = user?.id;
        }
        temppackagecart.push(packageCart);
      });
    var tempcart = {
      productid: dressdata.id,
      packages: JSON.stringify(temppackagecart),
      cartamount: dressdata.price,
      cartquantity: 1,
      price: dressdata.price,
      name: dressdata.name,
      // userid: user?.id,
    };
    var cart = [tempcart];
    dispatch({ type: "ADD_TO_CART", payload: tempcart });
    handlePackages(JSON.parse(tempcart.packages), tempcart.packages);

    setTimeout(() => {
      setOpen(true);
      setTOpen(true);
    }, 1000);

    setShowCart(true);
    dispatch({ type: "CHECKOUT_STATUS", payload: 1 });
  };

  const handleClose = () => {
    setOpen(false);
    setTOpen(false);
  };

  const handleAddtoCart = (item) => {
    setRefresh(!refresh);
    dispatch({ type: "ADD_TO_CART", payload: item });
  };

  const handlePackages = (packages, ustring) => {
    packages = packages?.map((item) => {
      if (item) {
        return { ...item, ustring: ustring };
      } else {
        return { ...item };
      }
    });
    console.log(packages);
    packages?.forEach((item) => {
      dispatch({ type: "ADD_PACKAGES", payload: item });
    });
    setRefresh(!refresh);
  };

  // useEffect(() => {
  //   if (packagesreduxdata?.length == 0) {
  //     cartreduxdata.forEach((item) => {
  //       handlePackages(JSON.parse(`[${item.packages}]`), item.packages);
  //     });
  //   }
  // }, []);

  const handleDispatchPackages = (item, ustring) => {
    item = JSON.stringify(item);
    handlePackages(JSON.parse(`[${item}]`), ustring);
  };

  const handleMinusPackages = (packages, ustring) => {
    packages = packages?.map((item) => {
      if (item) {
        return { ...item, ustring: ustring };
      } else {
        return { ...item };
      }
    });
    console.log(packages);
    packages?.forEach((item) => {
      dispatch({ type: "MINUS_PACKAGES", payload: item });
    });
    setRefresh(!refresh);
  };

  const MinustoPackages = (item, ustring) => {
    item = JSON.stringify(item);
    handleMinusPackages(JSON.parse(`[${item}]`), ustring);
  };

  const handleDispatchPersonalised = (item) => {
    dispatch({ type: "ADD_PERSONALISED", payload: item });
    setRefresh(!refresh);
  };

  const handleMinusPersonalised = (item) => {
    dispatch({ type: "MINUS_PERSONALISED", payload: item });
    setRefresh(!refresh);
  };

  const handleShowPackages = (ustring) => {
    return packagesreduxdata?.map((item, index) => {
      return (
        <div key={index}>
          {item.ustring == ustring && item.cartquantity ? (
            <>
              {item.name} qty:- {item.cartquantity}{" "}
              <i
                className="fa-solid fa-plus"
                onClick={() => handleDispatchPackages(item)}
              ></i>
              <i
                className="fa-solid fa-minus"
                onClick={() => handleMinustoCart(item)}
              ></i>{" "}
            </>
          ) : (
            <></>
          )}
        </div>
      );
    });
  };

  console.log(has);

  const handleShowPackages2 = (packages, ustring) => {
    return packages.map((item, index, arr) => {
      return (
        <div key={index}>
          {item ? (
            <>
              <table className="cartMainAddTbl">
                <tr>
                  <td>{item?.name}</td>
                  <td>
                    £{"  "}
                    {
                      packagesreduxdata?.find(
                        (pitem) =>
                          pitem?.id == item?.id && pitem?.ustring == ustring
                      )?.cartamount
                    }
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-plus"
                      onClick={() => handleDispatchPackages(item, ustring)}
                    ></i>
                    {
                      packagesreduxdata?.find(
                        (pitem) =>
                          pitem?.id == item?.id && pitem?.ustring == ustring
                      )?.cartquantity
                    }
                    <i
                      className="fa-solid fa-minus"
                      onClick={() => MinustoPackages(item, ustring)}
                    ></i>
                  </td>
                </tr>
              </table>
            </>
          ) : (
            <></>
          )}
        </div>
      );
    });
  };

  const handleShowPersonalised = () => {
    return personalisedreduxdata.map((item, index, arr) => {
      return (
        <div key={index}>
          {item ? (
            <>
              <table className="cartMainAddTbl">
                <tr>
                  <td>{item.name}</td>
                  <b>Personalised </b>
                  <td>
                    £{"  "}
                    {
                      personalisedreduxdata?.find(
                        (pitem) => pitem?.id == item.id
                      )?.cartamount
                    }
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-plus"
                      onClick={() => {
                        handleDispatchPersonalised(item);
                      }}
                    ></i>
                    {
                      personalisedreduxdata?.find(
                        (pitem) => pitem?.id == item.id
                      )?.cartquantity
                    }
                    <i
                      className="fa-solid fa-minus"
                      onClick={() => {
                        handleMinusPersonalised(item);
                      }}
                    ></i>
                  </td>
                </tr>
              </table>
            </>
          ) : (
            <></>
          )}
        </div>
      );
    });
  };

  const handleSubmitData = () => {
    // cartreduxdata.forEach((item) => {
    //   mutationaddtocart.mutate(item);
    // });
    // packagesreduxdata.forEach((item) => {
    //   if (item?.cartquantity && item.name) {
    //     mutationpackage.mutate(item);
    //   }
    // });

    navigate("/details");
  };

  const handleMinustoCart = (item) => {
    var packclear = { productid: item.productid, ustring: item.packages };
    dispatch({ type: "MINUS_TO_CART", payload: item });
    dispatch({ type: "MINUS_PACKAGES_WITH_CART", payload: packclear });
    setRefresh(!refresh);
  };

  const CartModal = (props) => {
    return (
      <Modal
        className="basketModalSec"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Your Basket
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {cartreduxdata?.map((item, index) => {
            return (
              <div key={index}>
                <table className="cartMainTbl">
                  <tr>
                    <td>
                      <h3>
                        {item.name}
                        {"  "} £{item.cartamount}
                        {"        "}
                      </h3>
                      {handleShowPackages2(
                        JSON.parse(item.packages),
                        item.packages
                      )}

                      {has ? handleShowPersonalised() : <></>}
                    </td>
                    <td>
                      <i
                        className="fa-solid fa-plus"
                        onClick={() => handleAddtoCart(item)}
                      ></i>
                      {item.cartquantity}
                      <i
                        className="fa-solid fa-minus"
                        onClick={() => handleMinustoCart(item)}
                      ></i>
                    </td>
                  </tr>
                </table>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <div className="cartModalAmt">
            <h4>
              <b>Product Price:</b> £{total}
            </h4>
            <h4>
              <b>Additional Package Amount:</b>£{packtotal}{" "}
            </h4>
            <h4>
              <b>Grand Total:</b> £
              {parseInt(total) +
                parseInt(packtotal) +
                parseInt(personalisedtotal)}
            </h4>
          </div>
          <div className="btnSec">
            <Button onClick={props.onHide}>Close</Button>
            <Button variant="success" onClick={handleSubmitData}>
              Proceed to Checkout
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div>
      <div className="addcart-section section">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="cart-product">
                <img
                  src={
                    dressdata
                      ? `${imageapi}images/${dressdata?.jsonimage?.picture0}`
                      : ``
                  }
                  alt="image"
                />
              </div>
            </div>
            <div className="col-sm-8">
              <div className="productDetailSec">
                <h2>{dressdata && dressdata.name}</h2>
                <h3>£{price}</h3>
                {/* <form> */}
                <div className="row">
                  {showPackages &&
                    packagedata &&
                    packagedata.data.map((item, index, arr) => {
                      return (
                        <div className="col-sm-6 mt-4" key={index}>
                          <label>
                            {item.name} (+£{item.amount})
                          </label>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={(e) => handleCal(item, e)}
                          >
                            <option>No</option>
                            <option>Standard (+£{item.amount})</option>
                            {item.id == 1 && (
                              <option value="55"> Personalised (+£55) </option>
                            )}
                          </select>
                        </div>
                      );
                    })}

                  {showPackages &&
                    subservicedata?.name !=
                      "Groom"&&(
                        <div className="col-sm-6 mt-4">
                          <label>The Value of Your Dress</label>
                          <div className="inputboxWithIcon">
                            <input
                              type="number"
                              name=""
                              class="form-control"
                              id=""
                              // placeholder="+£"
                            />
                            <span>£</span>
                          </div>
                        </div>
                      )}
                  {subservicedata?.name != "Groom" && (
                    <div className="col-sm-12 mt-4">
                      <textarea
                        className="form-control"
                        rows={4}
                        placeholder="Type Your Personalisation Below"
                      ></textarea>
                    </div>
                  )}

                  <div className="col-sm-6 mt-1">
                    {showCart ? (
                      <Link to="/cart">
                        <button className="btn2 mt-4">Go to cart</button>
                      </Link>
                    ) : (
                      <button
                        className="btn2 mt-4"
                        onClick={() => handleCart()}
                      >
                        Add to cart
                      </button>
                    )}
                  </div>
                </div>
                {/* </form> */}
              </div>
              {dressdata.serviceid !== 13 && (
                <div className="productDesc">
                  <h3>Description</h3>
                  <p>{dressdata && dressdata.description}</p>

                  {dressdata.id !== 25 && (
                    <>
                      <p>
                        We will have your wedding dress looking as good as new
                        regardless of when the wedding was, or how long it's
                        been stored away.
                      </p>
                      <p>
                        Your wedding dress will be carefully cleaned and
                        pressed, before being wrapped with pH-neutral tissue
                        paper and expertly packed into one of our beautiful.
                      </p>
                      <p>
                        A courier will then deliver your freshly pressed wedding
                        dress and accessories ready for storage for years to
                        come.
                      </p>
                      <p>
                        This service includes a full clean and minor repairs
                        (e.g. hook & eyes, reattaching buttons if you supply us
                        with them and fixing loose threads etc). If your dress
                        has tears or needs any other major damage repaired we
                        can offer you a quote for this.
                      </p>
                      <p>FREE UK wide collection and delivery included.</p>
                      <p>
                        You can choose a personal message to put top here on the
                        box.
                      </p>
                      <h3>How it works</h3>
                      <ul className="textList">
                        <li>
                          Order online and we'll send your postage pack with
                          instructions on how to pack your wedding dress{" "}
                        </li>
                        <li>
                          Once your dress and accessories are ready for
                          collection, email us with your order number and
                          preferred collection day
                        </li>
                        <li>
                          Our courier will collect your items, and we will let
                          you know your ites have arrived
                        </li>
                        <li>Everything goes through thorough diagnostics </li>
                        <li>
                          Your items will be cleaned by hand and minor repairs
                          are made at this stage if required{" "}
                        </li>
                        <li>
                          Your items then move on to the pressing and steaming
                          stage before heading to our studio
                        </li>
                        <li>
                          Your dress is photographed and After Images are
                          emailed to you to keep. At this stage we ask you to
                          confirm your return address.
                        </li>
                        <li>
                          Once we have received confirmation of delivery
                          address, we will arrange our courier to collect from
                          us and deliver directly to your door - that's it!
                        </li>
                        <li>
                          Enjoy your beautifully preserved dress and accessories
                          for years to come!
                        </li>
                      </ul>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {topen ? <CartModal show={open} onHide={() => handleClose()} /> : <></>}
    </div>
  );
};

export default InnerDresses;
