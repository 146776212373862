import React from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import api from "./Api/api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();
  const checkoutstatus = useSelector((state) => state.checkoutstatus);
  const { auth, setAuth } = useAuth();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showSignup, setShowSignup] = useState(false);
  const [user, setUser] = useState();
  const [name, setName] = useState();
  const [mobile, setMobile] = useState();

  const navigate = useNavigate();

  const { data: cartdata, refetch: cartrefetch } = useQuery(
    ["logdata"],
    () => api.get(`users/getcartitems/${user}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,

      select: (res) => res.data,
    }
  );

  const { data: packagedata, refetch: packrefetch } = useQuery(
    ["packdata"],
    () => api.get(`users/getpackageitems/${user}`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,

      select: (res) => res.data,
    }
  );

  const mutationlogin = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "application/json",
        },
      };
      return api.post("admin/userlogin", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
      },
      onSuccess: (data, variables, context) => {
        if (data.data.status) {
          console.log("info", data.data);
          setAuth({ user: data.data });
          var user = { id: data.data.userid?.id };
          localStorage.setItem("user", JSON.stringify(user));
          setUser(data.data.userid?.id);
          localStorage.setItem("token", data.data.refreshtoken);
          setTimeout(() => {
            cartrefetch();
            packrefetch();
          }, 1000);
          if (checkoutstatus) {
            navigate("/details");
          } else {
            navigate("/");
          }
        } else {
          setShowSignup(true);
        }
      },
    }
  );

  const mutationsignup = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "application/json",
        },
      };
      return api.post("admin/usersignup", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
      },
      onSuccess: (data, variables, context) => {
        if (data.data.status) {
          setAuth({ user: data.data });
          var user = { id: data.data.userid };
          localStorage.setItem("user", JSON.stringify(user));
          setUser(data.data.userid);
          localStorage.setItem("token", data.data.refreshtoken);

          if (checkoutstatus) {
            navigate("/details");
          } else {
            navigate("/");
          }
        } else {
          setShowSignup(true);
        }
      },
    }
  );

  const mutationguest = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "application/json",
        },
      };
      return api.post("admin/guest", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
      },
      onSuccess: (data, variables, context) => {
        if (data.data.status) {
          setAuth({ user: data.data });
          var user = { id: data.data.userid };
          localStorage.setItem("user", JSON.stringify(user));
          setUser(data.data.userid);
          localStorage.setItem("token", data.data.refreshtoken);

          navigate("/details");
        } else {
          setShowSignup(true);
        }
      },
    }
  );

  useEffect(() => {
    cartdata?.data?.forEach((item) => {
      dispatch({ type: "ADD_TO_CART", payload: item });
    });
  }, cartdata);

  useEffect(() => {
    packagedata?.data?.forEach((item) => {
      dispatch({ type: "ADD_PACKAGES", payload: item });
    });
  }, packagedata);

  const handleLogin = (e) => {
    e.preventDefault();
    var body = { username: email, password: password };
    mutationlogin.mutate(body);
  };

  const handleSignup = (e) => {
    e.preventDefault();

    if (password.length < 11) {
      toast.error("Mobile no should be of atleast 11 digits", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }



    var body = { name, mobile, username: email, password: password };
    mutationsignup.mutate(body);
  };

  const handleGuest = (e) => {
    e.preventDefault();
    var body = { username: email };
    mutationguest.mutate(body);
  };






  return (
    <div>
      <div className="login-banner">
        <div class="container">
          <div className="row">
            {showSignup ? (
              <div className="col-md-4">
                <div className="login-inner">
                  <form>
                    <h2 className="heading">Sign Up</h2>
                    <div class="mb-3">
                      <label
                        for="exampleInputEmail1"
                        class="form-label"
                        requird="requird"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div class="mb-3">
                      <label
                        for="exampleInputEmail1"
                        class="form-label"
                        requird="requird"
                      >
                        Mobile
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setMobile(e.target.value)}
                      />
                    </div>

                    <div class="mb-3">
                      <label
                        for="exampleInputEmail1"
                        class="form-label"
                        requird="requird"
                      >
                        Email address
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleInputPassword1"
                        class="form-label"
                        requird="requird"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        class="form-control"
                        id="exampleInputPassword1"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <button
                      onClick={(e) => handleSignup(e)}
                      class="btn btn-light-large mt-2"
                    >
                      Sign Up
                    </button>

                    <p className="loginText">
                    Already have an account? <a
                        href="#"
                        style={{ color: "#fff" }}
                        onClick={() => setShowSignup(false)}
                      >
                        Log In{" "}
                      </a>
                      Now
                    </p>
                  </form>
                </div>
              </div>
            ) : (
              <div className="col-md-4">
                <div className="login-inner">
                  <form>
                    <h2 className="heading">Log In</h2>
                    <div class="mb-3">
                      <label
                        for="exampleInputEmail1"
                        class="form-label"
                        requird="requird"
                      >
                        Email address
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleInputPassword1"
                        class="form-label"
                        requird="requird"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        class="form-control"
                        id="exampleInputPassword1"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <button
                      onClick={(e) => handleLogin(e)}
                      class="btn btn-light-large mt-2"
                    >
                      Log In
                    </button>
                    <p className="loginText">
                    Don't have an account? <a
                        href="#"
                        style={{ color: "#fff" }}
                        onClick={() => setShowSignup(true)}
                      >
                        Register Now{" "}
                      </a>
                    </p>
                    {/* <label style={{ marginLeft: "20px" }}>
                  Forgot your password?{" "}
                  <a href="#" style={{ color: "#fff" }}>
                    Reset it here.
                  </a>
                </label> */}
                  </form>
                </div>
              </div>
            )}
            <div className="col-md-2"></div>
            {checkoutstatus ? (
              <div class="col-md-4">
                <div className="login-inner">
                  <h2 className="heading">Guest Checkout</h2>
                  <div className="form-group">
                    <label
                      for="exampleInputEmail1"
                      class="form-label"
                      requird="requird"
                    >
                      Email address
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <button
                    // onClick={(e) => handle(e)}
                    onClick={(e) => handleGuest(e)}
                    class="btn btn-light-large mt-2"
                  >
                    Guest CheckOut
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
