import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import "react-multi-carousel/lib/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Login from "./Login";
import Contact from "./Contact";
import About from "./About";
import Services from "./Services";
import Dresses from "./Dresses";
import InnerDresses from "./InnerDresses";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import Main from "./Main";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import RequireAuth from "./RequireAuth";
import useAuth from "./hooks/useAuth";
import MyAccount from "./MyAccount";
import Details from "./Details";
import Checkout from "./Checkout";
import Products from "./Products";
import Cart from "./Cart";
import SubServices from "./SubServices";
import Faq from "./Faq";
import Thankyou from "./Thankyou";
import ThankyouOrderPlace from "./ThankyouOrderPlace";
import { Terms } from "./Terms";
import Fitcheck from "./Fitcheck";


function App() {
  const { auth, setAuth } = useAuth();

  useEffect(() => {
    if (typeof window != "undefined") {
      if (localStorage.getItem("token")) {
        setAuth({ token: localStorage.getItem("token") });
      }
    }
  }, []);

  console.log(auth);

  return (
    <PayPalScriptProvider
      options={{ "client-id": process.env.REACT_APP_CLIENT_ID }}
    >
      <Router>
        <Header />

        <Routes>
          <Route element={<Main />} path="/" />
          <Route element={<Contact />} path="/contact" />
          <Route element={<Login />} path="/login" />
          <Route element={<About />} path="/about" />
          <Route element={<Dresses />} path="/dresses" />
          <Route element={<Services />} path="/services" />
          <Route element={<SubServices />} path="/subservices" />
          <Route element={<Fitcheck />} path="/fitcheck" />

          <Route element={<InnerDresses />} path="/innerdresses" />
          <Route element={<Products />} path="/products" />
          <Route element={<Products />} path="/products" />
          <Route element={<Cart />} path="/cart" />
          <Route element={<Faq />} path="/faq" />
          <Route element={<Thankyou />} path="/thankyou" />
          <Route element={<ThankyouOrderPlace />} path="/thankyouorderplace" />
          <Route element={<Terms />} path="/terms" />





          <Route element={<RequireAuth />}>
          <Route element={<Details />} path="/details" />
            <Route element={<Checkout />} path="/checkout" />
            <Route element={<MyAccount />} path="/myaccount" />
          </Route>
        </Routes>
        <Footer />
      </Router>
    </PayPalScriptProvider>
  );
}

export default App;
