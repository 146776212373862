import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api, { imageapi } from "./Api/api";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

const Dresses = () => {
  const servicedata = useSelector((state) => state.services);
  const subservicedata = useSelector((state) => state.subservices);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(200, 200);
  }, []);

  const {
    data: dressdata,

    refetch,
  } = useQuery(
    ["dress", servicedata],
    () =>
      api.get(
        `users/getproducts/${servicedata.id}/${
          servicedata.hasSubservices ? subservicedata.id : 0
        }`
      ),
    {
      // refetchOnWindowFocus: false,
      // refetchOnmount: false,
      // refetchOnReconnect: false,
      // retry: false,
      // enabled: false,

      select: (res) => res.data,
    }
  );

  const handleClick = (item) => {
    dispatch({ type: "ADD_DRESS", payload: item });
  };
  console.log(dressdata && dressdata);
  var tempDress = [];
  tempDress = dressdata?.data.map((item) => {
    if (item.image) {
      return { ...item, jsonimage: JSON.parse(item.image) };
    }
  });
  tempDress = tempDress && tempDress.filter((item) => item != undefined);
  console.log(servicedata);

  return (
    <div>
      <div
        className={
          servicedata?.id == 13
            ? "innerbanner-section section preservationBox"
            : "innerbanner-section section"
        }
      >
        <h1 className="text-center mt-5">
          {servicedata?.hasSubservices != 0
            ? subservicedata?.name
            : servicedata.name}
        </h1>
      </div>

      <div className="dress-section section">
        <div className="container">
          <div className="row">
            {tempDress &&
              tempDress.map((item, index) => {
                return (
                  <div className="col-sm-4" key={index}>
                    <Link to="/innerdresses">
                      <div
                        onClick={() => handleClick(item)}
                        className="inner-dress"
                      >
                        <div className="top">
                          <img
                            src={`${imageapi}images/${item.jsonimage.picture0}`}
                            alt="image"
                          />
                        </div>
                        <div className="middle">
                          <h5>{item.name}</h5>
                          {item.description.length > 0 && (
                            <p>{item.description}</p>
                          )}
                          {/* <Link to="/innerdresses">
                          <a
                            href="#"
                            className="btn2"
                            onClick={() => handleClick(item)}
                          >
                            Check Service
                          </a>
                        </Link> */}
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>

        {tempDress?.length === 0 && (
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="coming_soon">
                  <img src="images/coming_soon.png" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dresses;
