import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import api, { imageapi } from "./Api/api";

const FitCheck = () => {
  const dispatch = useDispatch();

  const { data: fitcheckdata, refetch: fitcheckrefetch } = useQuery(
    ["fdata"],
    () => api.get(`users/getfitcheckdata`),
    {
      select: (res) => res.data,
    }
  );

  const { data: featureproductdata, refetch: featureproductrefetch } = useQuery(
    ["featureproductdata"],
    () => api.get(`users/getfeatureproductdata`),
    {
      select: (res) => res.data,
    }
  );

  const { data: productsdata, refetch: productsdatarefetch } = useQuery(
    ["productsdata"],
    () => api.get(`users/getproductsforfitcheck`),
    {
      select: (res) => res.data,
    }
  );

  const { data: servicedata, refetch: servicedatarefetch } = useQuery(
    ["servicedata"],
    () => api.get(`users/getservices`),
    {
      select: (res) => res.data,
    }
  );

  const featurelist = [];
  featureproductdata?.data?.forEach((item) => {
    featurelist.push(item.productid);
  });

  var featureproducts = productsdata?.data?.filter((item) =>
    featurelist?.includes(item.id)
  );

  var tempfeatureproducts = [];
  tempfeatureproducts = featureproducts?.map((item) => {
    if (item.image) {
      return { ...item, jsonimage: JSON.parse(item.image) };
    }
  });

  tempfeatureproducts = tempfeatureproducts?.map((item) => {
    return {
      ...item,
      status: servicedata?.data?.find((si) => si.id == item.serviceid)
        ?.hasSubservices,
    };
  });

  console.log(tempfeatureproducts);
  const handleClick = (item) => {
    dispatch({ type: "ADD_DRESS", payload: item });
  };
  return (
    <div>
      <div className="innerBannerSection fitCheckheader">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="bannerText">
                <figure>
                  <img
                    src={
                      "https://weddingdresscleaninglondon.co.uk/images/logo.png"
                    }
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fitcheckInstaList">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul>
                {fitcheckdata?.data.map((item, index) => {
                  return (
                    <li key={index}>
                      <div class="productBox largeProdBox">
                        <figure>
                          {/* <a href={`${item.url}`} target="_blank"> */}
                            <img
                              src={`${imageapi}images/${item.picture}`}
                              alt="image"
                              className="img-fluid"
                            />
                          {/* </a> */}
                        </figure>
                        <h3>
                            {item.url}
                        </h3>
                        <p>{item.url_label}</p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="fitCheckTagline">
        <h3>- Tag Us In Your Pictures To Be Featured -</h3>
        <h4>Updated Weekly!</h4>
      </div>

      <div className="dress-section section">
        <div className="container">
          <div className="row">
            {tempfeatureproducts?.map((item) => {
              return (
                <div key={item.id} className="col-sm-4">
                  <Link to="/innerdresses">
                    <div
                      onClick={() => handleClick(item)}
                      className="inner-dress"
                    >
                      <div className="top">
                        <img
                          src={`${imageapi}images/${item?.jsonimage?.picture0}`}
                          alt="image"
                        />
                      </div>
                      <div className="middle">
                        <h5>{item.name}</h5>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FitCheck;
