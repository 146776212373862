import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import useAuth from "./hooks/useAuth";
const RequireAuth = () => {
  const { auth, setAuth } = useAuth();

 

  const location = useLocation();
  return auth?.user||auth?.token||localStorage.getItem("user") ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
