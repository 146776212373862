import React from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "./Api/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";

const OrderSummary = () => {
  const cartreduxdata = useSelector((state) => state.cart);
  const packagesreduxdata = useSelector((state) => state.packages);
  const personalisedreduxdata = useSelector((state) => state.personalised);


  var orderTotal = 0;
  cartreduxdata?.forEach((item) => {
    orderTotal += item.cartamount;
  });
  var packtotal = 0;

  packagesreduxdata.forEach((item) => {
    if (item && item.cartamount) {
      packtotal += item.cartamount;
    }
  });


  personalisedreduxdata.forEach((item) => {
    if (item && item.cartamount) {
      packtotal += item.cartamount;
    }
  })





  return (
    <div>
      <div class="checkout_detail_box">
        {/* <h3>order summary</h3> */}
        <div>
          <table class="checkout_tbl2">
            <tbody>
              <tr>
                <td>
                  <b>Total Amount To Pay</b>
                </td>
                {/* <td><b>:</b></td> */}
                <td>
                  <b>
                    <i class="fas fa-pound-sign"></i> {orderTotal+packtotal}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* ORDER SUMMARY Grand Total = {orderTotal} */}
    </div>
  );
};

export default OrderSummary;
