import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const ThankyouOrderPlace = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  }, []);

  setTimeout(() => {
    navigate("/");
  }, 5000);
  var timer = 4;
  setInterval(() => {
    return (
      <>
        <p>Redirecting in {timer} s</p>
      </>
    );
    timer--;
  }, 1000);

  return (
    <div className="thankyouSec">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="thankyouContent">
              <h1>Thank you</h1>
              <p>Thankyou your order has been successfully placed.</p>
              <img src="images/thank-u.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankyouOrderPlace;
