import { useMutation } from "@tanstack/react-query";
import api from "./Api/api";
import React, { useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Reaptcha from "reaptcha";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Contact = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [message, setmessage] = useState();
  const [name, setName] = useState();
  const [subject, setSubject] = useState();
  const [accept, setAccept] = useState(false);
  const navigate = useNavigate();

  var captchaRef = useRef(null);
  const mutationcontact = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "application/json",
        },
      };
      return api.post("users/contactus", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
      },
      onSuccess: (data, variables, context) => {
        toast.success("Submitted!", {
          position: toast.POSITION.TOP_CENTER,
        });
        navigate("/thankyou");
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.length < 11) {
      toast.error("Mobile no should be of atleast 11 digits", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    if (accept) {
      var body = { email, password, message, name, subject };
      mutationcontact.mutate(body);
      console.log("hi");
    } else {
      toast.error("Please fill the captcha", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const verify = async () => {
    var token = await captchaRef.current.getResponse();
    console.log(token);
    if (token) {
      setAccept(true);
    }
  };

  return (
    <>
      <div className="innerbanner-section section contactSec">
        <h1 className="text-center">Contact Us</h1>
      </div>

      <div className="container">
        <div className="contact-inner">
          <div className="row">
            <div className="col-sm-7 p-0">
              <div className="left-inner">
                <h3>
                  Send us a <span>message</span>
                </h3>
                <form>
                  <div className="row">
                    <div class="col-sm-6 mb-3">
                      <label
                        for="exampleInputEmail1"
                        class="form-label"
                        requird="requird"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div class="col-sm-6 mb-3">
                      <label
                        for="exampleInputEmail1"
                        class="form-label"
                        requird="requird"
                      >
                        Subject
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>

                    <div class="col-sm-6 mb-3">
                      <label
                        for="exampleInputEmail1"
                        class="form-label"
                        requird="requird"
                      >
                        Email address
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div class="col-sm-6 mb-3">
                      <label
                        for="exampleInputPassword1"
                        class="form-label"
                        requird="requird"
                      >
                        Phone Number
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputPassword1"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <div style={{ marginBottom: "25px" }} className="row">
                    <label
                      for="exampleInputPassword1"
                      class="form-label"
                      requird="requird"
                    >
                      Message
                    </label>
                    <textarea onChange={(e) => setmessage(e.target.value)} />
                  </div>
                  <Reaptcha
                    sitekey={"6LfP2GEiAAAAACA8TSWHJf9yDAHwFvEzVswed1oK"}
                    ref={captchaRef}
                    onVerify={verify}
                  />
                  <button
                    className="btn2"
                    style={{ "margin-top": "25px" }}
                    onClick={(e) => handleSubmit(e)}
                  >
                    Send Message
                  </button>
                </form>
              </div>
            </div>
            <div className="col-sm-5 p-0">
              <div className="right-inner">
                <h3>Wedding Dress Cleaning London</h3>
                <ul className="mt-5">
                  <li>
                    <a href="#">
                      <i class="fas fa-mail-bulk"></i>
                      contact@weddingdresscleaninglondon.co.uk
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fas fa-map-marker-alt"></i>92 Elmers End Rd
                      Beckenham Kent BR34TA
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fas fa-phone"></i>02036054411
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
