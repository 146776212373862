import axios from "axios";
import React from "react";
// http://weddingdresscleaninglondon.co.uk:5001/
const api = axios.create({
  baseURL: "https://weddingdresscleaninglondon.co.uk:5001/",
  // baseURL: "https://localhost:5001/",
});

const imageapi = "https://weddingdresscleaninglondon.co.uk:5001/";
// const imageapi = "https://localhost:5001/";

export default api;
export { imageapi };
