import React from "react";

const Thankyou = () => {
  return (
    <div className="thankyouSec">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="thankyouContent">
              <h1>Thank you</h1>
              <p>Thankyou for contacting us. Our team will reach you soon.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
