import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import api, { imageapi } from "./Api/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Carousel } from "react-responsive-carousel";

const Main = () => {
  const prevScrollY = useRef(0);
  const [goingUp, setGoingUp] = useState(false);
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();

  const {
    data: servicedata,

    refetch,
  } = useQuery(["services"], () => api.get(`users/getservices`), {
    select: (res) => res.data,
  });

  const { data: bannerdata } = useQuery(
    ["banners"],
    () => api.get(`users/getbanners`),
    {
      select: (res) => res.data,
    }
  );

  // const mutationbanner = useMutation(
  //   (body) => {
  //     const headers = {
  //       headers: {
  //         "content-type": "application/json",
  //       },
  //     };
  //     return api.post("users/getbanners", body, headers);
  //   },
  //   {
  //     onError: (error, variables, context) => {
  //       console.log(error);
  //     },
  //     onSuccess: (data, variables, context) => {
  //       setBannerData(data?.data);
  //     },
  //   }
  // );

  const { data: aboutusdata } = useQuery(
    ["aboutus"],
    () => api.get(`users/aboutus`),
    {
      select: (res) => res.data,
    }
  );

  console.log(bannerdata);

  // useEffect(()=>{
  // window.location.reload()
  // },[])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;
      console.log(goingUp, currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const sliderData = [];

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("refresh"))) {
      window.location.reload();
      localStorage.setItem("refresh", JSON.stringify(0));
    }
  }, []);

  const handleClick = (item) => {
    dispatch({ type: "ADD_SERVICES", payload: item });
  };

  let services = servicedata?.data.sort((a, b) => b.priority - a.priority);
  services = services?.filter((item) => item.isHome == 1);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      {bannerdata?.data.map((item) => {
        return (
          <>
            <div className="banner-section">
              <div className="banner-inner">
                {/* <h1>
                  <span>
                    {item.title}
                    <br></br>
                  </span>{" "}
                </h1> */}
                <Link className="btn btn-light-large mt-4" to={"/services"}>
                  Book Your Dress cleaning
                  <i class="fa-solid fa-arrow-right"></i>
                </Link>
                {/* <a href="#" className="btn btn-light-large mt-4">
                  Buy Now
                </a> */}

                {/* <img src={`images/${item.bannerimage}`} alt="image" /> */}
              </div>
            </div>
            <div className="bannerTagLine">
              <marquee scrollamount="5">
                {" "}
                <h3>{item.tagline}</h3>
              </marquee>
            </div>
          </>
        );
      })}

      <div className="about-section section">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <img
                src={`${imageapi}/images/${aboutusdata?.data.picture}`}
                alt="image"
              />
            </div>
            <div className="col-sm-6">
              <div className="about-inner">
                <h2>
                  About <span>Us</span>
                </h2>

                <p className="mt-3">{aboutusdata?.data.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="service-section section">
        <div className="container">
          <h2 className="heading text-center">Services</h2>

          <div className="row">
            <div className="col-lg-2"></div>
            {services?.map((item, index) => {
              return (
                <div className="mt-4 col-lg-4 col-sm-6" key={index}>
                  <div className="service-inner">
                    <div className="top">
                      <img
                        src={`${imageapi}images/${item.picture}`}
                        alt="image"
                      />
                    </div>
                    <div className="buttom d-flex mt-2 justify-content-between">
                      <h3>
                        <Link to="/subservices">
                          <span onClick={() => handleClick(item)}>
                            
                          {item.name} <i class="fa-solid fa-arrow-right"></i>
                            
                          </span>
                        </Link>
                      </h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="testimonial-section testimonialSec">
        <div className="container">
          <h2 className="heading text-center">Testimonials</h2>
          <div className="row">
            <Carousel swipeable autoPlay>
              <div className="col-md-12">
                <div className="testimonial-inner">
                  <i class="fas fa-quote-left"></i>
                  <h2>- Helen Anglin</h2>
                  <p>
                    This place is fantastic for wedding dress alterations.
                    Although I'd already worn my dress down the aisle a few
                    years ago, I wanted it altered for future wear! I specified
                    before arriving what I wanted and tried on my dress when I
                    arrived to finalise the part I wanted cut. My zip and clasp
                    had also broken and these were fixed along with the wedding
                    dress alteration. It looks great and the service was very
                    friendly. Well priced too - would highly recommend.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="testimonial-inner">
                  <i class="fas fa-quote-left"></i>
                  <h2>- Elizabeth Stone</h2>
                  <p>
                    I was in a rush to find somewhere to clean my crepe wedding
                    dress before wearing it again and found Jay’s based on the
                    good reviews. He cleaned it very quickly and was very
                    communicative. The dress came back very clean and almost
                    good as new (a few bobbles on the underside of the dress,
                    but that’s to be expected and it looks great). There was
                    some delicate beading which was in perfect condition. Also
                    great value for money compared to others I got a quote from.
                    Thanks, Jay’s!
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="testimonial-inner">
                  <i class="fas fa-quote-left"></i>
                  <h2>- Alina Tonita</h2>
                  <p>
                    I absolutely loved my experience with Jay Dry Cleaners. I
                    wanted to clean and preserve my wedding dress and I was in
                    touch with Muhammad about every step of the process. He was
                    professional and reassured me that my wedding dress is in
                    good hands. Fair price and honesty. I can't recommend them
                    enough.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="testimonial-inner">
                  <i class="fas fa-quote-left"></i>
                  <h2>- Mel Carter</h2>
                  <p>
                  Nothing is too much trouble for Jay!! He’s extremely helpful, expert, professional and friendly. I took my daughter’s bridesmaid dress to be reduced by a whole size and it was perfect. Then I took my silk dress in to have the body shortened: again - perfect! And each dress was done within a week. I highly recommend Jay and his team, you are in safe hands!
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="testimonial-inner">
                  <i class="fas fa-quote-left"></i>
                  <h2>- Thomas Banks</h2>
                  <p>
                  Jays did an absolutely fantastic job on dry cleaning a suit and wedding dress for us. Came back spotless and they had taken great care to get out a fair few marks. Really impressed and friendly staff as well. Will be using again for sure.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="testimonial-inner">
                  <i class="fas fa-quote-left"></i>
                  <h2>- Megan J</h2>
                  <p>
                  They took great care in dry cleaning my very big aline wedding dress that had a very muddy and grass stained trail and fixing a tear on the lace detail. Looks brand new again. Very pleased with the service as they were friendly as well as being competent at their job. I will be using them again for any future wardrobe needs and recommending them to anyone else needing their assistance. Thanks again.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="testimonial-inner">
                  <i class="fas fa-quote-left"></i>
                  <h2>- Georgina Heneghan</h2>
                  <p>
                  Thanks so much for cleaning my wedding dress came out spotless and looking brand new! Quick turnaround and great value for money and so convenient for collecting and dropping off to my home in the evenings. Would highly recommend :) very professional and top service - much appreciated!
                  </p>
                </div>
              </div>
            </Carousel>
            <div className="col-md-12">
              <div className="googleReviewAllBtn"><a href="https://g.co/kgs/Lm33Xs" target="_blank">View all google reviews</a></div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Main;
