import { Button } from "react-bootstrap";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect } from "react";
const Cart = () => {
  const cartreduxdata = useSelector((state) => state.cart);
  const checkoutstatus = useSelector((state) => state.checkoutstatus);

  useEffect(() => {
    localStorage.setItem("refresh", JSON.stringify(1));
  }, []);

  return (
    <div>
      <div className="cartSec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Qty</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {cartreduxdata?.map((item) => {
                    return (
                      <tr>
                        <td>{item.name}</td>
                        <td>{item.cartquantity}</td>
                        <td>£{item.price}</td>
                      </tr>
                    );
                  })}
                </tbody>
                {/* <tr>
                  <td colSpan={2}>Total Amount</td>
                  <td>£{item.cartamount}</td>
                </tr> */}
              </table>
              <div align="right">
                <Link to="/details">
                  <Button className="btn2" variant="success">
                    Proceed to Checkout
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
