import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import api from "./Api/api";
import OrderSummary from "./OrderSummary";

const MyAccount = () => {
  const [userinfo, setUserinfo] = useState({});
  const [cust, setCust] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      setUserinfo(JSON.parse(localStorage.getItem("user")));
    }
  }, []);

  const { data: prodata } = useQuery(
    ["prodata", userinfo.id],
    () => api.get(`users/getorderproducts/${userinfo.id}`),
    {
      select: (res) => res.data,
    }
  );

  const { data: custdata } = useQuery(
    ["custdata", userinfo.id],
    () => api.get(`users/getcustomers/${userinfo.id}`),
    {
      select: (res) => res.data,
    }
  );

  const { data: userdata } = useQuery(
    ["userdata", userinfo.id],
    () => api.get(`users/getusers/${userinfo.id}`),
    {
      select: (res) => res.data,
    }
  );

  console.log(prodata && prodata);

  console.log(cust && cust);
  return (
    <div className="myAccountSec">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="heading">My Orders</h2>
            <table className="tbl">
              <tr>
                <th>Order ID</th>
                <th>Service Name</th>
                <th>Qty</th>
                <th>Amount</th>
              </tr>
              {prodata?.data.map((item) => {
                console.log(item);
                return (
                  <tr>
                    <td>{item.orderid}</td>
                    <td>{item.name}</td>
                    <td>{item.quantity} </td>
                    <td>{item.totalamount}</td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
      </div>

      {/* <h2>Your Account</h2>
      name:- {userdata?.data[0].name} <br />
      email:- {userdata?.data[0].username} <br />
      mobile:- {userdata?.data[0].mobile} <br />
      role:- {userdata?.data[0].role} <br /> */}
      {/* <h2>your address</h2>
      <br />
      Shipping address:- {custdata?.data?.address}
      <br /> */}
    </div>
  );
};

export default MyAccount;
