import React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import api from "./Api/api";
import { useSelector } from "react-redux";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userEvent from "@testing-library/user-event";
import OrderSummary from "./OrderSummary";

const CheckOut = () => {
  const navigate = useNavigate();
  const [paidfor, setPaidfor] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState({});
  const [oid, setOid] = useState();
  const [ono, setOno] = useState();
  const [send, setSend] = useState(false);

  const cartreduxdata = useSelector((state) => state.cart);
  const packagesreduxdata = useSelector((state) => state.packages);
  const dressdata = useSelector((state) => state.innerdress);
  const personalisedreduxdata = useSelector((state) => state.personalised);

  const {
    data: custdata,

    refetch: refetchcust,
  } = useQuery(["cust"], () => api.get(`users/getcustomers/${user.id}`), {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    enabled: false,
    select: (res) => res.data,
  });

  useEffect(() => {
    if (localStorage.getItem("user")) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }

    setTimeout(() => {
      refetchcust();
    }, 2000);
  }, []);

  const mutationpackage = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "application/json",
        },
      };
      return api.post("users/addtopackage", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
      },
      onSuccess: (data, variables, context) => {
        setTimeout(() => {
          navigate("/thankyouorderplace");
        }, 1000);
      },
    }
  );

  const {
    data: maildata,

    refetch: refetchmail,
  } = useQuery(
    ["mail"],
    () =>
      api.get(
        `users/getmail/${user.id}/${ono}/${custdata?.data?.name}/${custdata?.data?.last_name}/${custdata?.data?.address}/${gtotal}/${dressdata?.name}/${dressdata?.jsonimage?.picture0}/${dressdata?.price}/${cartreduxdata?.length}`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      select: (res) => res.data,
    }
  );

  const { data: orderdata, refetch: orderrefetch } = useQuery(
    ["orderdata"],
    () => api.get(`users/getorders`),
    {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      select: (res) => res.data,
    }
  );

  const mutationorderproducts = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "application/json",
        },
      };
      return api.post("users/orderproducts", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
      },
      onSuccess: (data, variables, context) => {
        console.log(data?.data);
      },
    }
  );

  const mutationorderplace = useMutation(
    (body) => {
      const headers = {
        headers: {
          "content-type": "application/json",
        },
      };
      return api.post("users/orderplace", body, headers);
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
      },
      onSuccess: (data, variables, context) => {
        var count = 10000 + parseInt(orderdata?.data.length) + 1;
        var orderno = "WDC" + count;

        let tempproducts = cartreduxdata?.map((item) => {
          return {
            ...item,
            userid: user.id,
            orderid: orderno,
          };
        });

        tempproducts?.forEach((item) => {
          mutationorderproducts.mutate(item);
        });
        var temppackages = [];
        temppackages = packagesreduxdata?.map((item) => {
          if (item?.cartquantity && item.name) {
            return {
              ...item,
              userid: user.id,
              orderid: orderno,
            };
          } else {
            return { ...item };
          }
        });

        if (personalisedreduxdata.length) {
          let tempPersonalisedPackages = personalisedreduxdata?.map((item) => {
            return {
              ...item,
              userid: user.id,
              orderid: orderno,
              ustring: cartreduxdata[0].packages,
            };
          });
          console.log(tempPersonalisedPackages);
          tempPersonalisedPackages.forEach((item) => {
            temppackages.push(item);
          });
        }

        console.log(temppackages);

        temppackages.forEach((item) => {
          if (item?.cartquantity && item.name) {
            mutationpackage.mutate(item);
          }
        });
        refetchmail();
        setSend(true);
      },
    }
  );

  useEffect(() => {
    if (maildata?.status && send) {
      setTimeout(() => {
        navigate("/thankyouorderplace");
      }, 1000);
    }
  }, [maildata]);

  useEffect(() => {
    localStorage.setItem("refresh", JSON.stringify(1));
  }, []);

  useEffect(() => {
    if (paidfor) {
      var count = 10000 + parseInt(orderdata?.data.length) + 1;
      var orderno = "WDC" + count;
      setOno(orderno);
      var body = {
        userid: user.id,
        totalamount: gtotal,
        customerid: custdata?.data?.id,
        transactionid: oid,
        orderno: orderno,
      };
      mutationorderplace.mutate(body);
    }
  }, [paidfor]);

  var total = 0;
  cartreduxdata.forEach((item) => {
    total += item.cartamount;
  });
  var packtotal = 0;

  packagesreduxdata.forEach((item) => {
    if (item && item.cartamount) {
      packtotal += item.cartamount;
    }
  });

  var gtotal = parseInt(total) + parseInt(packtotal);
  const handleApprove = (orderid) => {
    setOid(orderid);
    setPaidfor(true);
  };
  // if (paidfor) {
  //   alert("successfull");
  //   console.log("first")
  //   setTimeout(() => {
  //     navigate("/");
  //   }, 2000);
  // }
  if (error) {
    alert(error);
  }

  return (
    <div className="checkoutPaymentPage">
      <div className="container">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <OrderSummary />

            <PayPalScriptProvider>
              <PayPalButtons
                onClick={(data, action) => {
                  const hasBought = false;
                  if (hasBought) {
                    setError("you already bought");
                    return action.reject();
                  } else {
                    return action.resolve();
                  }
                }}
                createOrder={(data, action) => {
                  return action.order.create({
                    purchase_units: [
                      {
                        description: "great pay",
                        amount: {
                          value: gtotal,
                        },
                      },
                    ],
                  });
                }}
                onApprove={async (data, action) => {
                  const order = await action.order.capture();
                  handleApprove(data.orderID);
                }}
                onError={(err) => {
                  setError(err);
                  console.log("paypal checkout error");
                }}
              />
            </PayPalScriptProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOut;
