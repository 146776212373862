import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api, { imageapi } from "./Api/api";
import { useQuery } from "@tanstack/react-query";
const Products = () => {
  const dispatch = useDispatch();
  const servicedata = useSelector((state) => state.services);


  const {
    data: dressdata,

    refetch,
  } = useQuery(["dress"], () => api.get(`users/getproducts/${servicedata?.id}`), {
    // refetchOnWindowFocus: false,
    // refetchOnmount: false,
    // refetchOnReconnect: false,
    // retry: false,
    // enabled: false,

    select: (res) => res.data,
  });
  const handleClick = (item) => {
    dispatch({ type: "ADD_DRESS", payload: item });
  };
  console.log(dressdata && dressdata);
  var tempDress = [];
  tempDress = dressdata?.data.map((item) => {
    if (item.image) {
      return { ...item, jsonimage: JSON.parse(item.image) };
    }
  });
  tempDress = tempDress && tempDress.filter((item) => item != undefined);
  console.log(tempDress);

  return (
    <div>
      <div className="innerbanner-section section">
        <h1 className="text-center mt-5">Products</h1>
      </div>

      <div className="dress-section section">
        <div className="container">
       
          <div className="row">
            {tempDress &&
              tempDress.map((item, index) => {
                return (
                  <div className="col-sm-4" key={index}>
                    <div className="inner-dress">
                      <div className="top">
                        <img
                          src={`${imageapi}images/${item.jsonimage.picture0}`}
                          alt="image"
                        />
                      </div>
                      <div className="middle">
                        <h5>{item.name}</h5>
                        <p>{item.description.slice(0, 50)}</p>
                        <Link to="/innerdresses">
                          <a
                            href="#"
                            className="btn btn-dark-large mt-2"
                            onClick={() => handleClick(item)}
                          >
                            Check Price
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
