import { useQuery } from "@tanstack/react-query";
import React from "react";
import api, { imageapi } from "./Api/api";

const About = () => {
  const { data: aboutusdata } = useQuery(
    ["aboutus"],
    () => api.get(`users/aboutus`),
    {
      select: (res) => res.data,
    }
  );

  return (
    <div>
      <div className="innerbanner-section section">
        <h1 className="text-center mt-5">About Us</h1>
      </div>

      <div className="about-section section">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <img src={`${imageapi}/images/${aboutusdata?.data.picture}`} alt="image" />
            </div>
            <div className="col-sm-6">
              <div className="about-inner">
                <h2>
                  About <span>Us</span>
                </h2>

                <p className="mt-3">{aboutusdata?.data.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
