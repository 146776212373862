import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api, { imageapi } from "./Api/api";
import { useQuery } from "@tanstack/react-query";

const Services = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: servicedata,

    refetch,
  } = useQuery(["services"], () => api.get(`users/getservices`), {
    select: (res) => res.data,
  });

  const handleClick = (item) => {
    dispatch({ type: "ADD_SERVICES", payload: item });
    if (!item.hasSubservices) {
      navigate("/dresses");
    } else {
      navigate("/subservices");
    }
  };

  const { data: dressdata } = useQuery(
    ["dreses"],
    () => api.get(`users/getdressestocheck`),
    {
      select: (res) => res.data,
    }
  );

  let services = servicedata?.data.sort((a, b) => b.priority - a.priority);
  services = services?.map((item) => {
    return {
      ...item,
      proname: dressdata?.data?.find((fi) => fi.serviceid == item.id)?.name,
    };
  });
  console.log(services);

  return (
    <div>
      <div className="innerbanner-section section">
        <h1 className="text-center mt-5">Services</h1>
      </div>

      <div className="service-section section">
        <div className="container">
          <div className="row">
            {services?.map((item, index) => {
              return (
                <div className="mt-4 col-lg-4 col-sm-6" key={index}>
                  <div className="service-inner">
                    <div className="top">
                      <img
                        src={`${imageapi}images/${item.picture}`}
                        alt="image"
                      />
                    </div>
                    <div className="buttom d-flex mt-2 justify-content-between">
                      <h3>
                        {item.proname == undefined ? (
                          <a>
                            <span onClick={() => handleClick(item)}>
                              {item.name}{" "}
                              <i class="fa-solid fa-arrow-right"></i>
                            </span>
                          </a>
                        ) : (
                          <a>
                            <span onClick={() => handleClick(item)}>
                              {item.name}{" "}
                              <i class="fa-solid fa-arrow-right"></i>
                            </span>
                          </a>
                        )}
                      </h3>
                    </div>
                    {item.proname == undefined ? (
                      <div className="commingSoonStrip">Coming Soon</div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
